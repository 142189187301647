/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
	var gaCode = script_vars.ga_id; //// get the Google Code from tag-manager-head.php//'UA-85975096-1'; //
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // slick basic
        $('.slider').slick({
          autoplay: true,
          dots: true,
          arrows: false,
          autoplaySpeed: 10000
        });


        // stop video playback on modal close
        // http://stackoverflow.com/a/25069916
        $(".video-modal").on('hidden.bs.modal', function (e) {
          var id = $(this).attr('id');
          var target = "#" + id + ".video-modal iframe";

          $(target).attr("src", $(target).attr("src"));
        });

	      /**********************************************
	       * ********************************************
	       * ******* Equal heights
	       * ********************************************
	       */
	      var screenWidth = $( window ).width();
	      ///// Equalize height in rows
	      var equalheight = function (container, minsize) {
		      var currentTallest = 0,
				      currentRowStart = 0,
				      rowDivs = [],
				      $el,
				      $currentDiv,
				      topPosition = 0;
		      $(container).each(function () {
			      screenWidth = $( window ).width();
			      var classes = $(container).attr('class').split(' ');
			      for(var i = 0; i < classes.length; i++) {
				      //console.log(classes[i]);
			      }

			      $el = $(this);
			      $($el).height('auto');

			      if(screenWidth > minsize){
				      topPostion = $el.position().top;
				      if (currentRowStart !== topPostion) {
					      for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
						      rowDivs[currentDiv].height(currentTallest);
					      }
					      rowDivs.length = 0; // empty the array
					      currentRowStart = topPostion;
					      currentTallest = $el.height();
					      rowDivs.push($el);
				      } else {
					      rowDivs.push($el);
					      currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
				      }
				      for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
					      rowDivs[currentDiv].height(currentTallest);
				      }
			      }
		      });
	      };
	      ////// end equalheight();

	      function forceFlex(){
		      $('div').each(function () {
			      if ($(this).hasClass("flexed")) {
				      var holderHeight = $(this).height();
				      var innerHeight = $(this).children().height();
				      var halfDiff = ( holderHeight - innerHeight )/2;
				      //console.log( holderHeight + ' - ' + innerHeight + ' = ' + halfDiff );
				      $(this).children().css('margin-top', halfDiff);
			      }
		      })
	      }

        function wrappedResize(parentClasses, elemClass, ignoreSize) {
          var capHeight = 0;
          $(elemClass,$(parentClasses)).css({height: 'auto'});
          $(elemClass,$(parentClasses)).addClass({height: 'auto'});

          if(!isMobile || ignoreSize) {
            $(parentClasses).each(function(e){
              capHeight = 0;
              $(elemClass,$(this)).each(function(e){
                if($(this).outerHeight() > capHeight) {
                  capHeight = $(this).outerHeight();
                }
              });
              $(elemClass,$(this)).css({height: capHeight});
            });
          }
          //console.log( 'resize ' + capHeight );
          forceFlex();
        }
	      ////////////////////////////////
	      ////////////////////////////////

	      //// general equalize heights call after load fallback
	      // function equalizeHeights(){
		     //  equalheight('.card', 767);
	      // }
	      // setTimeout(function(){ equalizeHeights(); }, 1100);

        function resizeFuntions() {
         //wrappedResize('.transaction-cards','.undercard, .undercard .overlay', true);
	      var pageToCheck=  window.location.pathname;
	        if( pageToCheck === '/'){
		        wrappedResize('.transaction-cards','.life-sciences .undercard, .life-sciences .undercard .overlay', true);
		        wrappedResize('.transaction-cards','.healthcare .undercard, .healthcare .undercard .overlay', true);
	        }else{
		        wrappedResize('.transaction-cards','.undercard, .undercard .overlay', true);
	        }

          wrappedResize('.hero-boxes','.hero-card');
          wrappedResize('.card-repeater','.card-inner');
        }

        $('.dropdown').hover(function(e) {
          $(this).toggleClass('open');
        });

        $('.dropdown>.caret-wrapper').click(function(e) {
          e.stopPropagation();
          $(this).parent().toggleClass('mobile-open');
          $(this).toggleClass('flipped');
        });

        $('.no-link > a').click(function(e) {
          e.preventDefault();
        });

        isMobile = false;
        isTablet = false;

        if(Modernizr.mq('(max-width: 767px)')) {
          isMobile = true;
        }
        else {
          if(Modernizr.mq('(max-width: 1199px)')) {
            isTablet = true;
          }

          resizeFuntions();

          $('.news-insights-module').imagesLoaded( function() {
            wrappedResize('.news-insights-module','.card-inner');
          });
        }

        $('.team-grid').imagesLoaded( function() {
          setTimeout(function(e) {
            $('.team-grid-inner').isotope({
              itemSelector: '.undercard',
              layoutMode: 'fitRows'
            });

            // if($('.our-team-sort').length > 0) {
            //   $('.team-grid-inner').isotope({ filter: '.executive' });
            // }

	          $('.team-grid').animate({
		          opacity: 1
	          }, 1000, function() {
		          // Animation complete.
	          });
          }, 100);
        });

        $('.undercard .more').click(function(e) {
          e.preventDefault();
          var item = $(this).parent();
          item.addClass('animate');

          setTimeout(function() {
            item.addClass('overlaid');
          }, 1);
        });

        $('.undercard .overlay-close').click(function(e) {
          e.preventDefault();
          var item = $(this).parent().parent();
          item.removeClass('overlaid');

          setTimeout(function() {
            item.removeClass('animate');
          }, 500);
        });

        // $('.our-team-sort a').click(function(e) {
        //   e.preventDefault();
        //   $('.our-team-sort li').removeClass('active');
        //   $(this).parent().addClass('active');
        //   var filterValue = $(this).attr('data-filter');
        //   $('.team-grid-inner').isotope({ filter: filterValue });
        // });

        function getParameterByName(name) {
          var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
          return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
        }

        $('.transaction-cards').imagesLoaded( function() {
          //wrappedResize('.transaction-cards','.undercard, .undercard .overlay, .transaction-cards .overlay-inner', true);

          setTimeout(function(e) {
            $('.transaction-grid-inner').isotope({
              itemSelector: '.item',
              layoutMode: 'fitRows'
            });

            //$('.transaction-cards .overlay').addClass('noshow');

            if($('.transactions-sort').length > 0 && getParameterByName('type')) {
              $('.transactions-sort .'+getParameterByName('type')+'-filter a').click();
            }
          }, 100);
        });

        $('.transactions-sort a').click(function(e) {
          e.preventDefault();
          $('.transactions-sort li').removeClass('active');
          $(this).parent().addClass('active');
          var filterValue = $(this).attr('data-filter');
          $('.transaction-grid-inner').isotope({ filter: filterValue });
        });

        if(!isMobile) {
          resizeFuntions();
        }

        $(window).resize(function() {
          if(Modernizr.mq('(max-width: 767px)')) {
            isMobile = true;
          }
          else {
            isMobile = false;

            if(Modernizr.mq('(max-width: 1199px)')) {
              isTablet = true;
            }
            else {
              isTablet = false;
            }
          }

          resizeFuntions();

        });

        var animateItems = $('.custom-elem, .custom-list');

        if (Modernizr.touchevents) {
          animateItems.addClass('animate-elem');
          animateItems.addClass('shown');
        }
        else {
          var waypoints = animateItems.waypoint(function(direction) {
            if(direction === 'down') {
              var elem  = $(this.element);
              elem.addClass('animate-elem');
              elem.addClass('shown');
            }
          }, {
            offset: '75%'
          });
        }

	      setTimeout(function() {
		      resizeFuntions();
	      }, 5000);
	      /**********************************************
	       * ********************************************
	       * ******* Basic Tabs
	       * ********************************************
	       */
	      ///// modified to handle multiple tabgroups on a single page
	      function openTab(evt, tabName, tabGroup) {
		      // variables
		      var i, tabcontent, tablinks;

		      tabGroup = '#' + tabGroup;
		      jQuery(tabGroup).find('.tabcontent').removeClass('active');
		      jQuery(tabGroup).find('.tablinks').removeClass('active');

		      // Show the current tab, and add an "active" class to the button that opened the tab
		      var compTab = tabGroup + ' #' + tabName + '-Tab';
		      jQuery(compTab).addClass('active');
		      var compTabContent = tabGroup + ' #' + tabName;
		      jQuery(compTabContent).addClass('active');
	      }

	      $('.tablinks').click(function() {
		      var val = $(this).attr('id');
		      var par = $(this).parent().parent().parent().attr('id'); //// pass the parent to set scope
		      openTab(event, val, par);
	      });

	      ////// end openTab() and calls;
	      //////////////////////////////////
	      //////////////////////////////////

	      ////////////////////////////
	      //////// begin Dyanmic Outbound Link Logging
	      function getRootUrl() {
		      return window.location.origin?window.location.origin+'/':window.location.protocol+'/'+window.location.host+'/';
	      }

	      $('a').click(function(e){
		      //e.preventDefault(); - only for testing
		      //// we want to dynamically record outbound link values for GA
	      	  var baseCompare = getRootUrl(); //// get the root of the domain
		      var URLValue = $(this).attr("href"); //// get the value of the hyperlink address

		      var coverRelative = URLValue.indexOf('http'); //// it's possible for a relative link to sneak in - so if there's no http we know it's not outbound
		      var localCheck = false; //// set a variable to work through our checks
		      var isDomain = URLValue.indexOf(baseCompare); //// firstCheck and set value
		      if( isDomain === -1 ){
			      localCheck = false;
		      }else{
			      localCheck = true;
		      }
		      //// now we can make sure it's not just a relative link that tricked us
		      if( coverRelative === -1 ){
			      localCheck = true;
		      }
		      var endExternal = '';
		      if( !localCheck ){
			      var startExternal = URLValue.indexOf('//');
			      endExternal = URLValue.substr( startExternal+2 , URLValue.length );
			      var containsWWW = endExternal.indexOf('www');
			      if( containsWWW !== -1 ){ //// if www is present trim to just the domain and suffix
				      endExternal = endExternal.substr( 4 );
			      }
		      }
		      if( !localCheck ){
			      setTimeout(function(){
				      ga('create', gaCode, 'auto');
				      var pushCategoryToGoogle = 'Outbound Click';
				      var pushActionToGoogle = endExternal;
				      var pushLabelToGoogle = URLValue;
					  ga('send', 'event', pushCategoryToGoogle, pushActionToGoogle, pushLabelToGoogle);
		        }, 1000);
			  }

		      var isPDF = URLValue.indexOf('.pdf');
		      if( ( isPDF ) && ( URLValue !== '#' ) ) {
			      setTimeout(function () {
				      ga('create', gaCode, 'auto');
				      var pushCategoryToGoogle = 'PDF';
				      var pushActionToGoogle = 'Viewed';
				      var pushLabelToGoogle = URLValue;
				      ga('send', 'event', pushCategoryToGoogle, pushActionToGoogle, pushLabelToGoogle);
			      }, 1000);
		      }

	      });
	      /////// end Dyanmic Outbound Link Logging
	      /////////////////////

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
	 'contact_us': {
			 init: function() {
				 $(document).bind('gform_confirmation_loaded', function(event, formId){
					 setTimeout(function(){
						 ga('create', gaCode, 'auto');//
						 var pushPageToGoogle = 'contact-us/submitted';
						 if(pushPageToGoogle){
							 ga('set', 'page', pushPageToGoogle);
							 ga('send', 'pageview');
						 }
					 }, 1000);

					 var pushCategoryToGoogle = 'Main Contact Form';
					 var pushActionToGoogle = 'Submitted';
					 var screenWidth = $( window ).width();
					 var subDevice = 'Phone';
					 if(screenWidth > 480){
						 subDevice = 'Tablet';
					 }
					 if(screenWidth > 1029){
						 subDevice = 'Tablet-Desktop';
					 }
					 if(screenWidth > 1199){
						 subDevice = 'Desktop';
					 }
					 var pushLabelToGoogle = subDevice;
					 if(pushCategoryToGoogle){
						 setTimeout(function(){
							 ga('create', gaCode, 'auto');//
							 ga('send', 'event', pushCategoryToGoogle, pushActionToGoogle, pushLabelToGoogle);
						 }, 1000);

					 }
				 });
			 }
	 },
	////instead of page template this is important to a module that runs on many, but doesn't need to load 'common' either
	 //// the body class for this gets appended in setup.php - if you need to add more templates with this capability check there also
	'team_module': {
		  init: function() {
			  jQuery( document ).ready(function() {
				  //// team array from PHP
				  try {
					  teamArray = ox_script_vars.team_array;
				  }
				  catch(err) {
				  	    teamArray = ['not','needed'];
				  }

				  function teamModal(elem) {
            var id = elem.attr('id');
            id = id.replace('team','');

            var numID = Number(id);
            var slug = teamArray[numID].slug;
            var teamID = teamArray[numID].id;
            //var headshot = teamArray[numID].photo;
            var headshot = teamArray[numID].modal;
            var name = teamArray[numID].name;
            var first = teamArray[numID].first;
            var last = teamArray[numID].last;
            var title = teamArray[numID].job;
            var phone = teamArray[numID].phone;
            var cleanphone = teamArray[numID].cleanphone;
            var email = teamArray[numID].email;
            var linkedin = teamArray[numID].linkedin;
            var group = teamArray[numID].groups;
            var bio = teamArray[numID].bio;
            headshot = "<img src='" + headshot + "' />";

            var teamCardClass = '#team-modal .team-card';
            $(teamCardClass + " .team-card-groups").html('<h4>' + group + '</h4>');
            $(teamCardClass + " .team-card-name").html('<h2>' + name + '</h2>');
            $(teamCardClass + " .team-card-title").html('<h3>' + title + '</h3>');
            $(teamCardClass + " .team-card-bio").html(bio);
            $(teamCardClass + " .team-card-photo").html(headshot);

            if (linkedin) {
              linkedin = "<a href='" + linkedin + "' target='_blank''><i class=\"fa fa-linkedin-square\" aria-hidden=\"true\"></i></a>";
              $(teamCardClass + " .team-card-linkedin").html(linkedin);
            }
            else {
              $(teamCardClass + " .team-card-linkedin").html('');
            }

            if(email !== '') {
              $(teamCardClass + " .team-card-contact").html('<p><a href="mailto:' + email + '">' + email + '</a></p>');
              $(teamCardClass + " .team-card-email").html('<a href="mailto:' + email + '" class="btn btn-secondary"><i class="icon icon-email" aria-hidden="true"></i> Contact ' + first + '</a>');
            }
            else {
              $(teamCardClass + " .team-card-contact").html('');
              $(teamCardClass + " .team-card-email").html('');
            }
            //// clean number
            if(cleanphone !== '') {
              $(teamCardClass + " .team-card-phone").html('<a href="tel:' + cleanphone + '" class="btn btn-primary"><i class="icon icon-phone" aria-hidden="true"></i> ' + phone + '</a>');
            }
            else {
              $(teamCardClass + " .team-card-phone").html('');
            }

            var teamArrowsHTML = '';
            var itemTarget = elem.closest('.team-profile');

            if(itemTarget.prev().hasClass('team-profile') && itemTarget.prev().is(':visible')) {
              var prevID = itemTarget.prev().attr('id');
              teamArrowsHTML += '<a href="#" class="team-arrow prev-arrow" data-itemid="'+prevID+'"><i class="fa fa-chevron-left"></i></a>';
            }
            else {
              teamArrowsHTML += '<span class="team-arrow prev-arrow"><i class="fa fa-chevron-left"></i></span>';
            }
            if(itemTarget.next().hasClass('team-profile') && itemTarget.next().is(':visible')) {
              var nextID = itemTarget.next().attr('id');
              teamArrowsHTML += '<a href="#" class="team-arrow next-arrow" data-itemid="'+nextID+'"><i class="fa fa-chevron-right"></i></a>';
            }
            else {
              teamArrowsHTML += '<span class="team-arrow next-arrow"><i class="fa fa-chevron-right"></i></span>';
            }

            $(teamCardClass + ' .team-arrows').html(teamArrowsHTML);

            if(email == '') {
              $(teamCardClass + " .team-card-contact").css({display: 'none'});
              $(teamCardClass + " .team-card-email").css({display: 'none'});
            }
            else {
              $(teamCardClass + " .team-card-contact").css({display: 'block'});
              $(teamCardClass + " .team-card-email").css({display: 'inline-block'});
            }

            if(cleanphone == '') {
              $(teamCardClass + " .team-card-phone").css({display: 'none'});
            }
            else {
              $(teamCardClass + " .team-card-phone").css({display: 'inline-block'});
            }

            if(email == '' && cleanphone == '') {
              $(teamCardClass + " .contact-buttons").css({display: 'none'});
            }
            else {
              $(teamCardClass + " .contact-buttons").css({display: 'block'});
            }

            $("#team-modal").fadeIn('slow');

            var nextID = $('.next-arrow').data('itemid');
            if($('#'+nextID)) {
                $('.next-arrow').on('click', function(e) {
                  e.preventDefault();
                  e.stopPropagation();
                    teamModal($('#'+nextID));
                });
            }
            var prevID = $('.prev-arrow').data('itemid');
            if($('#'+prevID)) {
              $('.prev-arrow').on('click', function(e) {
                e.preventDefault();
                e.stopPropagation();
                teamModal($('#'+prevID));
              });
            }

            $(".team-card-text").scrollTop(0);


            $(".team-card-close, .team-overlay").click(function(e) {
              e.preventDefault();
              $("#team-modal").fadeOut('slow');
            });

            $('#team-modal .team-card-photo img').css({height: 'auto', width: '100%', maxWidth: '100%'});
            $('#team-modal .team-img-wrapper').css({height: 'auto'});

            if(isMobile) {
              $('#team-modal .team-card').css({top: $('.team-grid').offset().top + 20});
              $("html, body").animate({ scrollTop: $('.team-grid').offset().top });
            }
            else {
              $('#team-modal .team-card').css({top: '50%'});
              $('#team-modal .team-img-wrapper').css({height: '100%'});
            }

            /////////////////////////////////////
            //// add analytics to treat modal-view as a page
            var teamCompare = window.location.href; //// get page we're on
            var isTeamPage =  teamCompare.indexOf('/team'); //// team page or a module
            var linkBuild = '';
            if( isTeamPage !== -1 ){
              linkBuild = '/team/';
            }else{
              linkBuild = '/team-module/';
            }
            linkBuild = linkBuild + slug;
            setTimeout(function(){
              ga('create', gaCode, 'auto');
              var pushPageToGoogle = linkBuild;
              if(pushPageToGoogle){
                ga('set', 'page', pushPageToGoogle);
                ga('send', 'pageview');
              }
            }, 1000);
            ///// end GA modal-view code
            //////////////////////
          }

				  $(".team-profile").click(function(e) {
				    e.preventDefault();
					  teamModal($(this))
				  });

				  $(window).resize(function() {
            $('#team-modal .team-card-photo img').css({height: 'auto', width: '100%', maxWidth: '100%'});
            $('#team-modal .team-img-wrapper').css({height: 'auto'});

					  if(isMobile) {
						  $('#team-modal .team-card').css({top: $('.team-section, .team-grid').offset().top + 20});
					  }
					  else {
						  $('#team-modal .team-card').css({top: '50%'});
              $('#team-modal .team-img-wrapper').css({height: '100%'});
					  }
            // if(!isMobile && !isTablet) {
            //   $('#team-modal .team-card-photo img').css({height: $("#team-modal").outerHeight(), width: 'auto', maxWidth: 'none'});
            // }
				  });
			  });

		  },
		  finalize: function() {
			  // JavaScript to be fired on the home page, after the init JS
		  }
	  },
	  'team': {
		  init: function () {
			  jQuery(document).ready(function () {
				  //// hash stuff for deep-linking the team page
				  $(".hash-link").click(function(e) {
					  e.preventDefault();
					  var setHash = $(this).data('hash');
					  location.hash = setHash;
				  });

				  function changeHash(){
					  var getHash = location.hash;
					  getHash = getHash.substring(1, getHash.length);
					  getHash = '.' + getHash;
					  $('.team-grid-inner').isotope({ filter: getHash });

					  $('.our-team-sort li').removeClass('active');
					  $(getHash + '-filter').addClass('active');
				  }

				  window.onhashchange = changeHash;

				  // //// on first load
				  if (location.hash !== '') {
					  changeHash();
				  } else {
					  $('.team-grid-inner').isotope({filter: '.executive'});
					  $('executive-filter').addClass('active');
				  }
			  });
		  },
		  finalize: function () {
			  // JavaScript to be fired on the home page, after the init JS
		  }
	  }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.//
